/*
 * Tabs and filters
 *
 */
.tab {
  display: none;
}

.tab.active {
  display: block;
}

.tabs {
  @include tabs;

  ul {
    border-color: rgba(var(--color-base-string), 0.41);

    li {
      height: initial;
      display: inline-block;

      a {
        border: 0;
        background: rgba(var(--color-base-string), 0.1);
        color: $color_text;
        font-weight: 200;

        @include min-screen(768) {
          padding: 0.75em 3em 0.25em;
          margin: 0 1em;
        }

        padding: 0.75em 1em 0.25em;
        margin: 0 0.25em;
      }

      a:hover {
        background: rgba(var(--color-base-string), 0.3);
      }
    }

    li.active {
      a {
        border: 1px solid rgba(var(--color-base-string), 0.41);
        border-bottom: 1px solid #fff;
        background: $color_main_negative;
        color: $color_text;
        margin-bottom: -1px;
      }
    }

    @include screen(740) {
      li {
        a {
          padding: 0.75em 1.5em 0.25em;
          margin: 0 0.2em;
        }
      }
    }
  }
}

.sub_filter {
  font-size: 0.85em;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 1em;
    font-weight: normal;
  }

  li.active {
    position: relative;
    font-weight: bold;
  }

  li.active::after {
    top: 110%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: white;
    border-top-color: var(--color-base);
    border-width: 10px;
    margin-left: -10px;

    @include border-radius(2px);
  }

  li:hover a {
    background: rgba(var(--color-base-string), 0.2);
  }
}

.filter_boxed {
  padding: 0;
  margin: 0 0 1.5em;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0.5rem;

    li {
      position: relative;
      margin: 0 1.5em 0 0;
      display: inline-block;

      a {
        text-transform: uppercase;
        text-decoration: none;
        padding: 0.4em 0.6em;
        display: inline-block;
        border: 1px solid $color_separator;
        color: $text_soft;
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }
    }

    li:hover {
      a {
        background: rgba(var(--color-base-string), 0.06);
      }
    }

    li.active {
      a {
        background: rgba(var(--color-base-string), 0.06);
        color: var(--color-base);
        font-weight: bold;
        border: 0;
      }
    }

    li.active::after {
      top: 101%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: white;
      border-top-color: rgba(var(--color-base-string), 0.06);
      border-width: 10px;
      margin-left: -10px;

      @include border-radius(2px);
    }
  }
}

.filter_boxed.compact {
  ul {
    li {
      a {
        padding: 0.25em 1em 0.2em;
        margin: 0 0.5em 0 0;
      }
    }
  }
}
