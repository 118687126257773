/*
 * Lists
 *
 */
ul.block_list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    a {
      background: rgba(var(--color-base-string), 0.06);

      @include border-radius(4px);

      margin: 0 0 1em;
      padding: 0.5em 1em;
      display: block;
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }

    .fas {
      margin: 0.25em 0.5em 0 0;
    }

    a:hover {
      background: rgba(var(--color-base-string), 0.3);
    }
  }
}

.item-list {
  .block {
    h2 {
      margin-bottom: 0;
    }
  }
}
