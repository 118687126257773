@use "sass:color";

/*
 * Flash messages
 *
 */
.success,
.error,
.alert,
.notice {
  padding: 1em;
  margin-bottom: 2em;
  font-size: $f5;
  border-left: 10px solid;

  .fas {
    font-size: 1.5em;
    margin: 0 1em 0 0;
    vertical-align: top;
  }

  &.slim {
    padding: 1em;
    font-size: 0.9em;
  }
}

.success {
  background: #cc0;
  border-color: color.adjust(#cc0, $lightness: -20%);
  color: #fff;
}

.error {
  background: #f96;
  border-color: color.adjust(#f96, $lightness: -20%);
  color: #fff;
}

.alert {
  background: #ffebe0;
  border-color: color.adjust(#ffebe0, $lightness: -20%);
  color: #ae5d3b;
}

.notice {
  background: #f5f5cc;
  border-color: color.adjust(#f5f5cc, $lightness: -20%);
  color: #787a03;
}

.field_with_errors {
  input {
    border: 1px solid #f96;
  }
}
