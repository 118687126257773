.person_event-item,
.place_event-item,
.place_news-item,
.place_latest-item {
  .month,
  .hour {
    font-size: 0.85em;
  }

  .hour {
    opacity: 0.5;
  }
}

.event-content,
.news-content,
.latest-content {
  padding: 0;
  box-sizing: border-box;

  @include min-screen(768) {
    padding: 0 0 0 1.5em;
  }

  .meta {
    font-size: 0.85em;
  }

  h2,
  h3 {
    margin: 0.5em 0 0.75em;
  }

  .event-data {
    border-top: 1px solid $color_separator;
  }

  .event-description {
    padding: 2em 0;
  }

  .person-item {
    padding: 0 0 1em;
    border: 0;
  }

  .event-locations {
    box-sizing: border-box;
    padding: 0 0 0 2em;
  }

  .person-name {
    h2 {
      margin-top: 0;
    }
  }

  @include screen(768) {
    > span.soft {
      display: none;
    }
  }
}

.events-filter {
  .filter-option:not(.active) {
    @extend .soft;
  }
}
